html, body {
	width: 100%;
	height: 100%;
	margin: 0;
	font: 14px/1.21 'Libre Baskerville', 'Helvetica Neue', arial, sans-serif;
	font-weight: 400;
}

body > div {
	
	background-attachment: fixed;
	background-size: cover;
}

.background-image {
	background: url('/assets/background-img.jpg');
}

.background-image-blue {
	background: url('/assets/background-img-blue.jpg')
}

.background-image, .background-image-blue {
	position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    transform: translateZ(0);
    will-change: transform;
	z-index: -2;
}

@media (hover: none) {
	body > div {
		background-attachment: initial;
	}
 }

.home-container {
	width: 100%;
	max-width: 1300px;
	margin: 0 auto;
	padding: 2rem;
	padding-left: 4rem;
	padding-right: 4rem;
	box-sizing: border-box;
}

.home-container h1 {
	margin-bottom: .8rem;
}
.home-container h3 {
	margin-top: 0;
	margin-bottom: .9rem;
}

.header-text {
	margin-left: 11px;
	text-align: center;
	color: #D2D8DC;
	margin-bottom: 3rem;
	position: relative;
}

.header-text button {
	padding: .5rem 1rem;
	font-size: 16px;
	background: black;
	border: 1px solid #0000002e;
	border-radius: 4px;
	color: white;
	text-transform: capitalize;
	letter-spacing: 2px;
	transition: .3s;
}
.header-text button:hover {
	cursor: pointer;
	background: grey;
}

.album-container {
	position: relative;
	width: 25%;
	display: inline-block;
}

.lightswitch-container {
	color: white;
}

.lightswitch-mobile {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.lightswitch-container:hover {
	cursor: pointer;
}

.lightswitch-container img {
	max-width: 40px;
	cursor: pointer;
}

.lightswitch-container h5 {
	margin-top: 5px;
	margin-bottom: 5px;
}

@media screen and (max-width: 1100px) {
	.album-container {
		width: 50%;
	}
	.album-container > a > img {
		width: 93%;
		margin: 1rem;
	}
}

@media screen and (max-width: 700px) {
	.album-container {
		width: 100%;
		margin-bottom: 1rem;
	}

	.album-container > a > img {
		width: 100%;
		margin: 0;
	}
	
	.overlay p {
		font-size: 12px;
	}

	.overlay > .album-text p:nth-child(2) {
		font-size: 10px;
	}

	.header-text {
		margin-left: 0px;
		text-align: center;
	}

	.lightswitch-container img {
		max-width: 40px;
		cursor: pointer;
	}

	.home-container {
		padding-left: 2rem;
		padding-right: 2rem;
	}
}

.album-container img {
	width: 90%;
	margin: 1rem;
}

.overlay {
	transition: .3s ease;
	background-color: #000000cd;
	opacity: 0;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	font-size: 14px;
}

.overlay:hover {
	cursor: pointer;
}

.overlay p {
	color: white;
}

.overlay a {
	color: #3ee4ff;
	text-decoration: none;
}

.album-text {
	padding: 2rem;
	overflow-wrap: break-word;
}

.album-text p:nth-child(2) {
	font-size: 12px;
	letter-spacing: -.5px;
}

#album-1:hover .overlay, #album-2:hover .overlay, #album-3:hover .overlay, #album-4:hover .overlay, #album-5:hover .overlay, #album-6:hover .overlay, #album-7:hover .overlay, #album-8:hover .overlay, 
#album-9:hover .overlay, #album-10:hover .overlay, #album-11:hover .overlay, #album-12:hover .overlay, #album-13:hover .overlay, #album-14:hover .overlay, #album-15:hover .overlay, #album-16:hover .overlay, 
#album-17:hover .overlay, #album-18:hover .overlay, #album-19:hover .overlay, #album-20:hover .overlay, #album-21:hover .overlay, #album-22:hover .overlay, #album-23:hover .overlay, #album-24:hover .overlay, 
#album-25:hover .overlay, #album-26:hover .overlay, #album-27:hover .overlay, #album-28:hover .overlay, #album-29:hover .overlay, #album-30:hover .overlay, #album-31:hover .overlay, #album-32:hover .overlay,
#album-33:hover .overlay, #album-34:hover .overlay, #album-35:hover .overlay, #album-36:hover .overlay, #album-37:hover .overlay, #album-38:hover .overlay, #album-39:hover .overlay, #album-40:hover .overlay,
#album-41:hover .overlay, #album-42:hover .overlay, #album-43:hover .overlay, #album-44:hover .overlay, #album-45:hover .overlay, #album-46:hover .overlay, #album-47:hover .overlay, #album-48:hover .overlay,
#album-49:hover .overlay, #album-50:hover .overlay, #album-51:hover .overlay, #album-52:hover .overlay, #album-53:hover .overlay, #album-54:hover .overlay, #album-55:hover .overlay, #album-56:hover .overlay, #album-57:hover .overlay,
#album-58:hover .overlay {
	opacity: 1;
}